import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://37d71cd1c7162b27ba6115c79a07cbe0@o4507023097856000.ingest.us.sentry.io/4507023104933888",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});